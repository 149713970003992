import * as React from "react"
// import PropTypes from "prop-types"
// import { Link } from "gatsby"
import { HeaderStyled } from "./styles/header.styled"


export default function sammeHvaFunksjonenHeter() {
  return (
    <HeaderStyled>
     <h1>Jeg er Vegard, ordspillkongen!</h1> 
     <p>Velkommen til min hjemmeside </p>
    </HeaderStyled>
  )
}

